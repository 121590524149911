<template>
  <v-app>
    <core-drawer />

    <core-appbar />

    <v-main>
      <v-container fluid>
        <v-fade-transition mode="out-in">
          <router-view />
        </v-fade-transition>
      </v-container>
    </v-main>

    <core-footer />
  </v-app>
</template>

<script>
export default {
  name: "DashboardView",

  metaInfo() {
    return {
      title: "Wallin Licence Manager",
    };
  },
};
</script>

<style></style>
